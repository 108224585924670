import { EMerchantPixelsAndKeys } from '@olo-web/types/enums/merchantPixelsAndKeys.enum';
import {
  useCheckoutState,
  useCustomerDispatch,
  useGlobalUIState,
  useScreenSizeState,
  useAlertDispatch,
  useSavedDineInContextDispatch,
  useCheckoutDispatch,
  useSavedDineInContextState,
  useSavedOrderDispatch,
} from '@olo-web/client-state';
import axios, { AxiosError } from 'axios';
import get from 'lodash/get';
import { useMutation, UseMutationOptions } from 'react-query';
import { useUpdateOrderInCache } from '@olo-web/domain/orders/hooks/useUpdateOrderInCache';
import { EAnalyticsEventNames, ECheckoutTypes, EAlertTypes, ECookies } from '@olo-web/types/enums';
import {
  useSendEvent,
  useCurrentOrderTips,
  useIsScanAndPayPage,
  useIsLevelup,
  useIsDineIn,
  useToast,
  useHandleAsapLogicOnServer,
  useHasStartedTab,
  useIsThirdPartyDelivery,
  useGoToMenu,
} from '@olo-web/utils/common/hooks';
import { useMerchantTheme } from '@domain/merchants/queries/useMerchantTheme';
import { useOrder } from '@olo-web/domain/orders/queries/useOrder';
import { calculateTipAmount } from '@olo-web/domain/orders/functions/calculateTips';
import { EPaymentMethods } from '@olo-web/types/enums/paymentMethods.enum';
import { trackError } from '@olo-web/utils/metrics/datadog';
import { useLoyalty } from '@olo-web/domain/customer/hooks';
import { useUnsubscribeToNats } from '@olo-web/utils/common/nats/useNats';
import { EOrderStatus } from '@olo-web/types/enums/orderStatus.enum';
import { useIsSplitByAmount } from '@olo-web/utils/common/hooks/useIsSplitByAmount';
import { IExtraParameters } from '@olo-web/types/extraParameters.interface';
import { useErrorOrderScenarios } from '@domain/orders/hooks';
import { setCookie } from 'nookies';

declare const window: any;

export type CustomerSessionDetails = Pick<
  ICustomerInfo,
  'customerId' | 'sessionId' | 'sessionSecret'
>;
declare global {
  interface Window {
    grecaptcha: {
      enterprise: {
        ready: (callback: () => void) => void;
        execute: (siteKey: string, options: { action: string }) => Promise<string>;
      };
    };
  }
}
const RECAPTCHA_SITE_KEY = process.env.NEXT_PUBLIC_RECAPTCHA_SITE_KEY || '';

export type CheckoutProps = {
  merchantId: string;
  orderId: string;
  checkId?: string;
  guestId?: string;
  paymentForGuestIds?: string[];
  customerSessionDetails?: CustomerSessionDetails;
  paymentInfo?: IOrderPaymentInfo | IOrderPaymentInfo[];
  customer: Partial<ICustomer>;
  deliveryAddress?: IDeliveryAddress;
  orderNotes?: string;
  conversionId: string;
  conversionType?: 'google' | 'facebook';
  /*
        bpe = bypass pacing enforcement
        Used to allow DDD orders to bypass pacing rules.
        This happens when a DDD order fails in a specific time block,
        we allow the order to go through on the next one, whether
        available or not. Need to outline this more, but currently
        don't remember the entire reason why.
    */
  bpe?: boolean;
};

export type CompleteOrderProps = {
  merchantId: string;
  orderId: string;
  customerSessionDetails?: CustomerSessionDetails;
  orderDetails: {
    paymentInfo?: IOrderPaymentInfo | IOrderPaymentInfo[];
    customer: Partial<ICustomer>;
    deliveryAddress?: IDeliveryAddress;
    orderNotes?: string;
    recaptchaToken: string;
    conversionId: string;
    conversionType?: 'google' | 'facebook';
    isContactlessDelivery: boolean;
    scheduleThirdPartyDelivery: boolean;
    /*
        bpe = bypass pacing enforcement
        Used to allow DDD orders to bypass pacing rules.
        This happens when a DDD order fails in a specific time block,
        we allow the order to go through on the next one, whether
        available or not. Need to outline this more, but currently
        don't remember the entire reason why.
    */
    bpe?: boolean;
  } & IExtraParameters;
};

export interface CompleteOrderError extends Omit<AxiosError, 'code'> {
  message: string;
  alternateDateTimes?: string[];
  code?: string | number;
  additionalData?: {
    discountIds?: Array<string>;
    updatedOrder?: IOrder;
    order?: IOrder;
  };
}

export const completeOrder = async ({
  merchantId,
  orderId,
  customerSessionDetails,
  orderDetails,
}: CompleteOrderProps): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/orders/${orderId}/complete`;
    const { data } = await axios.post(url, orderDetails, {
      headers: {
        'so-session-secret': get(customerSessionDetails, 'sessionSecret', ''),
        'so-session-id': get(customerSessionDetails, 'sessionId', ''),
        'so-customer-id': get(customerSessionDetails, 'customerId', ''),
      },
    });
    return data;
  } catch (error) {
    throw {
      message: error?.response?.data?.message || error?.response?.data?.error || error,
      code: error?.response?.data?.code,
      alternateDateTimes: error?.response?.data?.alternateDateTimes,
      additionalData: error?.response?.data?.additionalData,
      status: error?.response?.status,
    };
  }
};

type AddPaymentProps = {
  merchantId: string;
  checkId: string;
  orderDetails: {
    paymentInfo: IOrderPaymentInfo | IOrderPaymentInfo[];
    customer: ICustomer | Partial<ICustomer>;
    guestId?: string;
    paymentForGuestIds?: string[];
  } & IExtraParameters;
  customerSessionDetails?: CustomerSessionDetails;
};

export const addPaymentToCheck = async ({
  merchantId,
  checkId,
  customerSessionDetails,
  orderDetails,
}: AddPaymentProps): Promise<IOrder> => {
  try {
    const url = `/api/merchants/${merchantId}/checks/${checkId}/payments`;
    const headers = {
      'so-session-secret': get(customerSessionDetails, 'sessionSecret', ''),
      'so-session-id': get(customerSessionDetails, 'sessionId', ''),
      'so-customer-id': get(customerSessionDetails, 'customerId', ''),
    };
    const { data } = await axios.post(url, orderDetails, { headers });
    return data;
  } catch (error) {
    throw {
      message: error?.response?.data?.message || error?.response?.data?.error || error,
      code: error?.response?.data?.code,
      alternateDateTimes: error?.response?.data?.alternateDateTimes,
      status: error?.response?.status,
    };
  }
};

export const useCheckout = (
  options?: UseMutationOptions<IOrder, CompleteOrderError, CheckoutProps>
) => {
  const updateOrderInCache = useUpdateOrderInCache();
  const { gclid, selectedPaymentMethod, giftCardNumber, utmParams } = useCheckoutState();
  const orderTips = useCurrentOrderTips();
  const { data: order } = useOrder();
  const { data: theme } = useMerchantTheme();
  const { sendEvent } = useSendEvent({ canRepeat: true });
  const { toast } = useToast();
  const { refetch: fetchLoyalty } = useLoyalty();
  const isScanAndPayPage = useIsScanAndPayPage();
  const orderDispatch = useSavedOrderDispatch();
  const isLevelup = useIsLevelup();
  const paymentTypes = [selectedPaymentMethod];
  const isDineIn = useIsDineIn();
  const savedDineInState = useSavedDineInContextState();
  const alertDispatch = useAlertDispatch();
  const isSplitByAmount = useIsSplitByAmount();
  const isThirdPartyDelivery = useIsThirdPartyDelivery();
  const { isContactlessDelivery } = useCheckoutState();
  const savedDineInDispatch = useSavedDineInContextDispatch();
  const checkoutDispatch = useCheckoutDispatch();
  const { isMd } = useScreenSizeState();
  const { kountSessionId } = useGlobalUIState();
  const handleAsapLogicOnServer = useHandleAsapLogicOnServer();
  const isUsingPreAuth = useHasStartedTab();
  const isStartATab = useHasStartedTab();
  const customerDispatch = useCustomerDispatch();
  const goToMenu = useGoToMenu();
  const errorOrderScenarios = useErrorOrderScenarios();

  const guestBalancesDefined = order?.guests?.reduce((a, c, i) => {
    a[`guest${i + 1}`] = {
      guestId: c?.id,
      guestBalance: c?.groupOrderBalanceAmount,
    };
    return a;
  }, {});
  const guestBalancesDefinedPaid = order?.payments?.reduce((a, c, i) => {
    if (c.guestId) {
      a[`guest${i + 1}`] = {
        guestId: c?.guestId,
        guestBalance: c?.totalAmount,
      };
    }
    return a;
  }, {});
  const guestBalancesPaid = Object.keys(guestBalancesDefinedPaid || {}).length;

  if (giftCardNumber) paymentTypes.push(EPaymentMethods.GIFT_CARD);
  const { unsubscribeToNats } = useUnsubscribeToNats();

  return useMutation(
    async ({
      merchantId,
      orderId,
      checkId,
      customerSessionDetails,
      paymentInfo,
      customer,
      paymentForGuestIds,
      ...rest
    }) => {
      if (typeof paymentInfo['amount'] === 'number') {
        paymentInfo['amount'] = paymentInfo['amount'].toFixed(2);
      }

      if (kountSessionId) {
        if (Array.isArray(paymentInfo)) {
          paymentInfo.forEach((payment) => {
            if (payment.vtsPaymentInfo) {
              payment.vtsPaymentInfo.transactionSessionId = kountSessionId;
            }
          });
        } else if (paymentInfo.vtsPaymentInfo) {
          paymentInfo.vtsPaymentInfo.transactionSessionId = kountSessionId;
        }
      }

      if (isScanAndPayPage || isLevelup || isDineIn) {
        const data = await addPaymentToCheck({
          merchantId,
          checkId,
          customerSessionDetails,
          orderDetails: {
            paymentInfo,
            customer,
            guestId: isDineIn ? savedDineInState?.guest?.id : null,
            paymentForGuestIds,
            extraCSData: utmParams
              ? {
                  utm_parameters: utmParams,
                }
              : null,
          },
        });
        return data;
      }

      const executeRecaptcha = async (): Promise<string | null> => {
        if (RECAPTCHA_SITE_KEY && window.grecaptcha) {
          try {
            await new Promise<void>((resolve) => window.grecaptcha.enterprise.ready(resolve));
            const token = await window.grecaptcha.enterprise.execute(RECAPTCHA_SITE_KEY, {
              action: `submit_${merchantId.replaceAll('-', '_')}_${orderId.replaceAll('-', '_')}`,
            });
            return token;
          } catch (error) {
            console.error('reCAPTCHA execution failed:', error);
            return null;
          }
        }
        console.warn('reCAPTCHA site key not provided or reCAPTCHA not loaded.');
        return null;
      };
      let recaptchaToken: string;
      try {
        // Execute reCAPTCHA in the background
        recaptchaToken = await executeRecaptcha();
        if (!recaptchaToken) {
          throw new Error('reCAPTCHA token not available');
        }
      } catch (e) {
        throw new Error('reCAPTCHA token not available');
      }

      return completeOrder({
        merchantId,
        orderId,
        customerSessionDetails,
        orderDetails: {
          recaptchaToken,
          paymentInfo,
          customer,
          isContactlessDelivery,
          scheduleThirdPartyDelivery: isThirdPartyDelivery,
          extraCSData: utmParams
            ? {
                utm_parameters: utmParams,
              }
            : null,
          ...rest,
        },
      });
    },
    {
      ...options,
      onSuccess: (data, variables, context) => {
        const { orderId } = variables;
        updateOrderInCache(data);
        fetchLoyalty();
        setCookie(null, ECookies.userId, data.customer?.spotOnCustomerId, {
          maxAge: 31536000, // One year in seconds
          path: '/',
        });

        // this is to prevent CC from being defaulted to if another start a tab order is attempted in the browser
        if (isStartATab) {
          customerDispatch({
            type: 'UPDATE',
            payload: { previousPaymentType: null },
          });
        }
        if (
          !!gclid &&
          window?.gtag &&
          theme?.[EMerchantPixelsAndKeys.GOOGLE_ADWORDS_KEY] &&
          theme?.[EMerchantPixelsAndKeys.GOOGLE_ADWORDS_CONVERSION_LABEL]
        ) {
          window.gtag('event', 'conversion', {
            // eslint-disable-next-line
            send_to: `${theme[EMerchantPixelsAndKeys.GOOGLE_ADWORDS_KEY]}/${
              theme[EMerchantPixelsAndKeys.GOOGLE_ADWORDS_CONVERSION_LABEL]
            }`,
            // eslint-disable-next-line
            transaction_id: orderId || '',
            value: data?.displayTotalAmount ? Number(data.displayTotalAmount) : 0,
            currency: 'USD',
            // eslint-disable-next-line
            customer_id: data?.customer?.spotOnCustomerID || data?.customer?.spotOnCustomerId || '',
          });
        }

        if (data?.groupOrderInfo?.status === EOrderStatus.COMPLETED) {
          savedDineInDispatch({
            type: 'UPDATE',
            payload: { guest: { id: data?.id, groupOrderBalancePaidFlag: true, name: data?.name } },
          });
          unsubscribeToNats();
        }

        const discountNames = data?.discounts?.map((d) => d.discountName).join(', ') || null;
        const discountTotal = data?.discounts?.reduce((a, c) => a + Number(c.displayAmount), 0);
        const surcharges = data?.surcharges?.reduce((a, c) => a + Number(c.displayAmount), 0) || 0;
        const tax = data?.displayTaxes?.reduce((a, c) => a + Number(c.displayAmount), 0) || 0;

        // @ts-ignore
        if (typeof fbq !== 'undefined') {
          // @ts-ignore
          fbq('track', 'Purchase', {
            value: data?.displayTotalAmount,
            currency: 'USD',
          });
        }

        sendEvent(EAnalyticsEventNames.PURCHASE, {
          googleAnalytics: {
            ecommerce: {
              menuItems: data?.items,
              value: data?.displayTotalAmount,
              shipping: surcharges, // using shipping because it's a recommend GA value with extra features attached
              tax,
            },
            eventMetadata: {
              guestBalancesDefined: guestBalancesDefined,
              guestBalancesDefinedPaid: guestBalancesDefinedPaid,
              numberOfGuests: order?.guests?.length,
              purchaseGuestId: savedDineInState?.guest?.id,
              guestBalancesPaid: guestBalancesPaid,
              isSitAndFireOrder: data?.isReserveOrder,
            },
            checkoutType: isSplitByAmount ? ECheckoutTypes.SPLIT_BY_AMOUNT : ECheckoutTypes.DEFAULT,
            channel: isScanAndPayPage ? 'qr-scan-pay' : isDineIn ? 'qr-order-pay' : 'olo-web',
            loyaltyPointsEarned: data?.loyaltyPointsEarned,
            discountNames,
            discountTotal,
            paymentType: selectedPaymentMethod,
            usedPreAuthCardForPayment: isUsingPreAuth && !isSplitByAmount,
            isPreAuthOrder: isUsingPreAuth,
            tipAmount:
              selectedPaymentMethod === EPaymentMethods.CASH
                ? 0
                : calculateTipAmount(+order?.preDiscountSubtotal, orderTips),
          },
        });

        if (options?.onSuccess) options.onSuccess(data, variables, context);
      },
      onError: (error, vars) => {
        if (error.status === 404) {
          orderDispatch({
            type: 'DISCARD_ID',
            payload: { merchantId: vars.merchantId, orderId: vars.orderId },
          });
          goToMenu();
        }
        if (handleAsapLogicOnServer) {
          checkoutDispatch({ type: 'COMPLETE_SUBMISSION' });
          if (error?.message.includes('AVS REJECTED')) {
            toast({
              status: 'error',
              title: "There's an issue with your card",
              description: 'Please update the ZIP code to match your card information',
              isClosable: true,
              duration: null,
              variant: 'left-accent',
              position: isMd ? 'top-right' : 'top',
            });
            console.error('[Checkout Error]', error);
          }
        }

        if (error.message === 'Payment amount total cannot exceed the check balance') {
          alertDispatch({
            type: 'OPEN_ALERT',
            payload: { alertKey: EAlertTypes.SBA_PAYMENT_OVER_BALANCE_ALERT },
          });
        }

        errorOrderScenarios(error, () => {
          if (
            handleAsapLogicOnServer &&
            !(
              isSplitByAmount &&
              error?.message === 'Payment amount total cannot exceed the check balance'
            )
          ) {
            checkoutDispatch({ type: 'REMOVE_PAYMENT' });
            toast({
              status: 'error',
              title: 'Error placing your order',
              description: error?.message ?? 'Something went wrong',
              isClosable: true,
              duration: null,
              variant: 'left-accent',
              position: isMd ? 'top-right' : 'top',
            });
          }
          sendEvent(EAnalyticsEventNames.PURCHASE_ERROR, {
            googleAnalytics: { errorMessage: error.message, paymentType: paymentTypes },
          });
        });

        trackError(error, {
          source: 'useCompleteOrder',
          payload: {
            ...vars,
            selectedPaymentMethod,
            giftCardNumber,
          },
        });
      },
    }
  );
};
